import React from "react";
import { CrofunCardType } from "../../../../types/Crofun.type";
import UserIconImgIons from "../../../ions/img/icon/UserIconImg.Ions";
import IdeaImgIons from "../../../ions/img/IdeaImg.Ions";
import DefaultProgressBarIons from "../../../ions/progress/DefaultProgressBar.Ions";
import { BodySubTextIons } from "../../../ions/text/body/BodySubText.Ions";
import { BodyTextBoldIons } from "../../../ions/text/body/BodyTextBold.Ions";
import { TagTextIons } from "../../../ions/text/tag/TagText.Ions";
import "./CrofunCard.Molecules.css";

type CrofunCardProps = {
  crofun: CrofunCardType;
  width: number | string;
  text_size?: "extra-small" | "small";
  hover_effect?: boolean;
  display_description?: boolean;
  onClick?: () => void;
};

export const CrofunCardMolecules: React.FC<CrofunCardProps> = React.memo(
  (props) => {
    const { crofun } = props;
    const handleScroll = () => {
      const targetElement = document.getElementById("works");
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    return (
      <div
        className={`crofun-card-molecules-container ${
          props.hover_effect ? "hover-effect" : ""
        }`}
        style={{
          width: props.width,
        }}
        onClick={() => {
          props.onClick && props.onClick();
        }}
      >
        <IdeaImgIons
          img_src={crofun.imgSrc}
          alt={crofun.title}
          className="crofun-card-molecules-img"
          nowOpen={crofun.nowOpen}
          commingSoon={crofun.commingSoon}
        />
        <main
          className="crofun-card-molecules-main"
          style={{
            gap: props.text_size === "small" ? "5px" : "10px",
          }}
        >
          <header className="crofun-card-molecules-header">
            <UserIconImgIons
              userName={crofun.userName}
              img_src={crofun.userImgSrc}
              size={props.text_size}
            />
            <section className="crofun-card-molecules-tags">
              <TagTextIons
                key={crofun.tag}
                text={crofun.tag}
                size={props.text_size}
              />
            </section>
          </header>

          <section
            className="crofun-card-molecules-texts"
            style={{
              gap: props.text_size === "small" ? "10px" : "5px",
            }}
          >
            <BodyTextBoldIons
              rows={1}
              size={props.text_size}
              text={props.crofun.title}
            />
            {props.display_description && crofun.description && (
              <BodySubTextIons
                rows={2}
                size={"extra-small"}
                text={props.crofun.description}
              />
            )}
          </section>

          <section className="crofun-card-molecules-progress">
            <DefaultProgressBarIons
              width={"60%"}
              progress={crofun.progress}
              size="small"
            />
            <BodySubTextIons
              text={`${crofun.progress === 0 ? "--" : crofun.progress} %`}
              size={props.text_size}
            />
          </section>

          <section className="crofun-card-molecules-progress">
            <div className="crofun-card-molecules-cell">
              <BodySubTextIons text={`${crofun.place}`} size={"extra-small"} />
            </div>
            <div className="crofun-card-molecules-cell">
              <BodySubTextIons
                text={`${crofun.startDate || "----年--月--日"}`}
                size={"extra-small"}
              />
            </div>
          </section>
        </main>
      </div>
    );
  }
);
