import { Stack } from "@mui/material";
import React, { useContext } from "react";
import { BaseGrid } from "reidea-common";
import { CROUD_FUNDINGS_DATA } from "../../../const/croundFundings";
import { MyContext } from "../../../router/router";
import YoutubeCard from "../../Card/youtubeCard";
import TitleColumn from "../../Title/Column";

// テキストの設定
const title: string = "海外クラファン\n0円から一括代行";
const subTitle: string = "RE-IDEAはあなたの技術を\n世界に届ける会社です";
const description: string =
  "RE-IDEAはあなたの技術を\n世界に届けるお手伝いをする会社です";

const Works: React.FC = () => {
  const {
    titleFontSize,
    subTitleFontSize,
    mainFontSize,
    headerSize,
    contentRatio,
    isXS,
    isSmall,
    isLarge,
    isMedium,
  } = useContext(MyContext);

  const _isMobile = isXS || isSmall;

  return (
    <Stack
      id={"works"}
      direction={"column"}
      width={"100%"}
      bgcolor={"#F5FBFE"}
      minWidth={"100%"}
      alignItems={"center"}
      py={_isMobile ? 8 : 15}
    >
      <TitleColumn
        title="導入事例"
        title_en="WORKS"
        fontSize={subTitleFontSize + 5}
      />
      <Stack mt={4} width={contentRatio * 100 + "%"}>
        <BaseGrid
          cols={1}
          spacingY={4}
          items={[
            // Urushi Knife
            <YoutubeCard
              fontSize={mainFontSize}
              imgSrc={CROUD_FUNDINGS_DATA.urushi.imgSrc}
              title={CROUD_FUNDINGS_DATA.urushi.title}
              plan={CROUD_FUNDINGS_DATA.urushi.plan}
              description={CROUD_FUNDINGS_DATA.urushi.description}
            />,

            // CHAKAWA
            <YoutubeCard
              fontSize={mainFontSize}
              imgSrc={CROUD_FUNDINGS_DATA.chawaka.imgSrc}
              title={CROUD_FUNDINGS_DATA.chawaka.title}
              plan={CROUD_FUNDINGS_DATA.chawaka.plan}
              description={CROUD_FUNDINGS_DATA.chawaka.description}
            />,

            // FUJIKO
            <YoutubeCard
              fontSize={mainFontSize}
              videoId="th8RXDnlSgU"
              title={CROUD_FUNDINGS_DATA.fujiko.title}
              plan={CROUD_FUNDINGS_DATA.fujiko.plan}
              description={CROUD_FUNDINGS_DATA.fujiko.description}
            />,

            // UWASA
            <YoutubeCard
              fontSize={mainFontSize}
              imgSrc={CROUD_FUNDINGS_DATA.uwasa.imgSrc}
              title={CROUD_FUNDINGS_DATA.uwasa.title}
              plan={CROUD_FUNDINGS_DATA.uwasa.plan}
              description={CROUD_FUNDINGS_DATA.uwasa.description}
            />,

            // YBB
            <YoutubeCard
              fontSize={mainFontSize}
              imgSrc={CROUD_FUNDINGS_DATA.ybb.imgSrc}
              title={CROUD_FUNDINGS_DATA.ybb.title}
              plan={CROUD_FUNDINGS_DATA.ybb.plan}
              description={CROUD_FUNDINGS_DATA.ybb.description}
            />,
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default Works;
