import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, ObserverStack} from "reidea-common"
import FlowCard from '../../Card/FlowCard';


const Flow: React.FC = () => {

    const { titleFontSize, subTitleFontSize, headerSize, mainFontSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);
    const flows = [
        {
          title: 'STEP1',
          subTitle: '無料ご相談',
          description: isSmall ? '無料ご相談で海外クラファンの説明や商品選定のご相談を承ります。気になる方はぜひお問い合わせください。'
                            : '無料ご相談で海外クラファンの説明や商品選定のご相談を承ります。気になる方はぜひお問い合わせください。'
        },
        {
          title: 'STEP2',
          subTitle: 'コンテンツ制作',
          description: isSmall ? 'ユーザーに商品の魅力を最大限伝えるために、海外向けの掲載文・動画・画像を専門デザイナーが作成します。'
                            : 'ユーザーに商品の魅力を最大限伝えるために、海外向けの掲載文・動画・画像を専門デザイナーが作成します。'
        },
        {
          title: 'STEP3',
          subTitle: '掲載開始',
          description: isSmall ? '準備が整い次第、掲載申請を行い、掲載後はユーザーのコメントや質問に丁寧に対応します。'
                            : '準備が整い次第、掲載申請を行い、掲載後はユーザーのコメントや質問に丁寧に対応します。'
        },
        {
          title: 'STEP4',
          subTitle: '海外配送',
          description: isSmall ? 'クラファン掲載後は商品の発送が必要です。弊社が海外配送を一括代行します。'
                            : 'クラファン掲載後は商品の発送が必要です。弊社が海外配送を一括代行します。'
        } 
      ];


    const componentHeight = isXS ? 170 :
                            isSmall ? 260 :
                            isMedium ? 240 : 
                            isLarge ? 240 : 0

    const _isMobile = isXS || isSmall;
    const [isViewed, setIsViewed] = useState<boolean>(false)
    const stackRef = useRef<HTMLDivElement>(null);  
    const cols = isXS ? 1 : isSmall ? 4 : 4

    return (
        <Stack id={"flow"} direction={"column"} width={"100%"} bgcolor={"#F5FBFE"}  minWidth={"100%"} alignItems={"center"} py={_isMobile ? 8 : 15}>
            <TitleColumn title='一連の流れ' title_en='FLOW' fontSize={subTitleFontSize+5}/>
            <Stack ref={stackRef} mt={2} width={_isMobile ? "78%" : contentRatio*100+"%"} justifyContent={"center"} >  
                <ObserverStack isOneTime={true} setObserved={setIsViewed}>
                    <BaseGrid animationInitialDisplay={false} animationOffsetY={0} enableAnimation={true} animation={isViewed} animationDelay={0.8} animationDuration={1.0}
                        cols={cols} spacingX={1} spacingY={isXS ? 0 : 4} items={
                        flows.map((value:any, index:number) => (
                            // widthは自動調整してくれるので、大きめに取っておく
                            <Stack width={"100%"}>
                                <FlowCard isMobile={_isMobile} displayArrow={!isSmall} direction={isXS ? "column" : "row"} last={index === 3} height={componentHeight} image={""} title={value.title} subTitle={value.subTitle} description={value.description} titleFontSize={subTitleFontSize} contentFontSize={mainFontSize} />
                            </Stack>
                        ))
                    }/>
                </ObserverStack>
            </Stack>
        </Stack>
    );
};

export default Flow;


