import { Typography } from "@mui/material";
import React, { useState } from "react";
import { LetterByLetter, ObserverStack } from "reidea-common";

interface Props {
  title: string;
  title_en: string;

  fontSize: number;
}

// コンテストのミニカルーセルを実装
const TitleColumn: React.FC<Props> = (props) => {
  const [isViewed, setIsViewed] = useState<boolean>(false);

  return (
    <ObserverStack
      isOneTime={true}
      setObserved={setIsViewed}
      alignItems={"center"}
    >
      <Typography
        style={{
          color: "#383E86",
          fontWeight: 800,
          fontSize: props.fontSize,
          letterSpacing: 2,
          zIndex: 2,
        }}
      >
        {props.title}
      </Typography>

      <LetterByLetter
        isAnimated={isViewed}
        text={props.title_en}
        offsetY={-30}
        delay={150}
        startDelay={500}
        style={{
          background: "linear-gradient(to right, #43cea2, #185a9d)", // グラデーションの色
          WebkitBackgroundClip: "text",
          color: "transparent",
          fontWeight: 200,
          fontSize: props.fontSize - 4,
          letterSpacing: 1.2,
        }}
      />
    </ObserverStack>
  );
};

export default TitleColumn;
