import React from "react";
import "./Img.Atoms.css";
import { ImgAtomsProps } from "./Img.type";

const ImgAtoms: React.FC<ImgAtomsProps> = React.memo(
  ({ img_src, alt, className, width, onClick }) => {
    return (
      <img
        src={process.env.PUBLIC_URL + "/images/" + img_src}
        alt={alt}
        className={`img-atoms ${className}`}
        style={{ width: width }}
        onClick={onClick}
      />
    );
  }
);

export default ImgAtoms;
