import React, { useState, useEffect, useContext } from 'react';
import { Typography, Stack, Divider } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { MyContext } from '../router/router';

const SpecifiedCommercialTransaction: React.FC = () => {

  const { titleFontSize, subTitleFontSize, headerSize, mainFontSize, contentRatio, isXS, isSmall, isLarge, isMedium } = useContext(MyContext);
  const isMobile = isXS || isSmall

  const title1Style = {
    color: 'black',
    fontSize: isMobile ? "24px" : '28px',
    fontWeight: 700,
  };
  

  
  const documentStyle = {
    color: 'black',
    fontSize: isMobile ? "14px" : '18px',
  };

  return (
    <Stack alignItems={'center'} bgcolor={'#F6F8FA'}>
      <Stack width={isMobile ? "85%" : '70%'} alignItems={'flex-start'} mt={headerSize+20+"px"} mb={isMobile ? 15 : 20}  spacing={5}>
        <Typography style={{...title1Style}}>
          特定商取引法に基づく記載/運営会社
        </Typography>

        <TableContainer component={Paper} sx={{boxShadow: 0}}>
          <Table >
            <TableBody>
              <TableRow>
                <TableCell width={isMobile ? "30%" : '20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                事業者名
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                株式会社RE-IDEA
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                運営統括責任者
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                代表取締役 中井涼祐
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                住所
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                〒151-0051 東京都渋谷区千駄ヶ谷1-30-10-4F
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                お問い合わせ先
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                TEL：03-6759-9299<br/>
                Mail：info@re-idea.jp
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                  設立
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                2024年5月31日
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                ご利用料金
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                各プラン記載の税込価格をご確認ください。
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                その他お客様の負担する費用
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                  ・海外クラウドファンディングサイトの決済時に生じる販売手数料9.5 %。<br/>
                  ・オプションとしてご提供する海外プロモーションの料金。<br/>
                  ・銀行振込の際の振込手数料。<br/>
                  <br/>
                  返品・キャンセルについて：製品の性質上、原則、商品の返品・キャンセルはお受けしておりません。
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                事業内容
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                ・日本の事業者様に対し、海外クラウドファンディングサイトへの掲載・コンテンツ制作・運営・海外配送までの一括代行サービスを提供。<br/>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                引渡時期
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                ・契約書締結後、3営業日以内にサービス提供を開始。
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                受付可能な銀行振込
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                ・国内の銀行振込
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                決済期間
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                （1）初期費用について、本契約締結後10日間以内<br/>
                （2）クラウドファンディングにおける売上の20％について、売上が着金した翌月末日限り
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default SpecifiedCommercialTransaction;