import { Stack } from "@mui/material";
import React from "react";
import { CrofunCardType } from "../../../types/Crofun.type";
import IdeaImgIons from "../../ions/img/IdeaImg.Ions";
import DefaultPopupIons from "../../ions/popup/DefaultPopup.Ions";
import DefaultProgressBarIons from "../../ions/progress/DefaultProgressBar.Ions";
import { BodySubTextIons } from "../../ions/text/body/BodySubText.Ions";
import { BodyTextIons } from "../../ions/text/body/BodyText.Ions";
import { BodyTextBoldIons } from "../../ions/text/body/BodyTextBold.Ions";
import "./CrowdFundingPopup.css";

interface PopupProps {
  open: boolean;
  onClose: () => void;
  crowdfund: CrofunCardType;
  isSP?: boolean;
}

const CrowdFundingPopup: React.FC<PopupProps> = React.memo(
  ({ open, onClose, crowdfund, isSP }) => {
    return (
      <DefaultPopupIons
        open={open}
        onClose={onClose}
        size="medium"
        zIndex={1000}
      >
        <div className={`crowd-funding-popup ${isSP ? "sp" : ""}`}>
          <IdeaImgIons
            img_src={crowdfund.imgSrc}
            width={"100%"}
            nowOpen={crowdfund.nowOpen}
            commingSoon={crowdfund.commingSoon}
          />

          <section className="crowd-funding-popup-attention">
            <Stack spacing={2}>
              <BodyTextBoldIons text={crowdfund.title} size="normal" />
              <BodyTextIons text={crowdfund.description} size="extra-small" />
            </Stack>
            <section className="crofun-card-molecules-progress">
              <DefaultProgressBarIons
                width={isSP ? "60%" : "70%"}
                progress={crowdfund.progress}
                size="medium"
              />
              <BodySubTextIons
                text={`${
                  crowdfund.progress === 0 ? "--" : crowdfund.progress
                } % 達成`}
                size={isSP ? "small" : "normal"}
              />
            </section>
            <Stack
              spacing={1}
              mt={1}
              width={"100%"}
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <BodyTextIons
                text={"会社: " + crowdfund.userName}
                size="extra-small"
              />
              <BodyTextIons
                text={"所在地: " + crowdfund.place}
                size="extra-small"
              />
            </Stack>

            {!crowdfund.commingSoon && (
              <BodyTextIons
                text={`${
                  crowdfund.commingSoon
                    ? "このプロジェクトは現在準備中です。"
                    : `プロジェクト開始日: ${crowdfund.startDate}`
                }`}
                size="extra-small"
              />
            )}
          </section>
        </div>
      </DefaultPopupIons>
    );
  }
);

export default CrowdFundingPopup;
