import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FlexDrawer } from "reidea-common";
import DefaultImgIons from "../../../atomics/ions/img/DefaultImg.Ions";
import { MyContext } from "../../../router/router";
import ApplyButton from "../../Button/applyButton";
import "./Header.css";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};

const Header: React.FC = () => {
  const {
    titleFontSize,
    subTitleFontSize,
    headerSize,
    contentRatio,
    isXS,
    isSmall,
    isLarge,
    isMedium,
  } = useContext(MyContext);

  const [fontColor, setFontColor] = useState<string>("white");
  const [open, setOpen] = useState<boolean>(false);
  const getIconSize = useCallback(() => {
    if (isXS) return "35px";
    return "60px"; // デフォルトサイズ (lg以上)
  }, [isXS]);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/privacyPolicy") {
      setFontColor("#383E86");
    } else if (location.pathname === "/company") {
      setFontColor("#383E86");
    }
    const handleScroll = () => {
      setOpen(false);
      const scrollPosition = window.scrollY;
      const meritSection = document.getElementById("news");
      if (meritSection) {
        const meritPosition = meritSection.offsetTop;
        // メリットセクションの上端に到達したときに色を変更
        if (scrollPosition >= meritPosition) {
          setFontColor("#383E86");
        } else {
          // setFontColor('white');
          setFontColor("#383E86");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    // 初期状態を設定
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const transitionTime = 1.5;
  const fontSize = isXS || isSmall ? 12 : 16;

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Stack
      position={"fixed"}
      zIndex={10}
      top={0}
      left={0}
      right={0}
      direction={"row"}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ transition: `backgroundColor ${transitionTime}s ease` }}
      height={headerSize + "px"}
      bgcolor={fontColor === "#383E86" ? "white" : "transparent"}
    >
      <Stack
        direction={"row"}
        width={(isXS ? 0.9 : 0.9) * 100 + "%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <DefaultImgIons
          img_src={"logo.png"}
          width={isXS ? 200 : isSmall ? 250 : 300}
          className="header-logo"
          onClick={() => {
            window.location.href = "/";
          }}
        />
        {!isXS && (
          <Stack direction={"row"} spacing={3} alignItems={"center"}>
            {[
              { text: "サービス内容", id: "service" },
              { text: "一連の流れ", id: "flow" },
              { text: "料金プラン", id: "amount" },
              { text: "導入一覧", id: "works" },
            ].map((value: any, index: number) => (
              <motion.div
                key={index}
                className="box"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.8 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                <Typography
                  onClick={() => {
                    handleScroll(value.id);
                  }}
                  fontWeight={600}
                  color={fontColor}
                  letterSpacing={2}
                  fontSize={fontSize}
                  sx={{
                    cursor: "pointer",
                    transition: `color ${transitionTime}s ease`,
                  }}
                >
                  {value.text}
                </Typography>
              </motion.div>
            ))}

            {!isXS && <ApplyButton text="無料ご相談はこちら" />}
          </Stack>
        )}
        {isXS && (
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
            color="inherit"
          >
            <MenuIcon
              sx={{ color: fontColor, width: "27px", height: "27px" }}
            />
          </IconButton>
        )}
      </Stack>

      <FlexDrawer width="80%" open={open} setOpen={setOpen}>
        <div>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            p={2}
          >
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <List>
            {[
              { text: "サービス内容", id: "service" },
              { text: "一連の流れ", id: "flow" },
              { text: "料金プラン", id: "amount" },
              { text: "導入一覧", id: "works" },
            ].map((section) => (
              <ListItem
                key={section.text}
                onClick={() => {
                  handleScroll(section.id);
                }}
              >
                <ListItemText
                  primary={
                    section.text.charAt(0).toUpperCase() + section.text.slice(1)
                  }
                  sx={{ fontWeight: "bold" }}
                />
              </ListItem>
            ))}
          </List>
          <Stack direction={"row"} justifyContent={"center"}>
            <Stack
              width={"60%"}
              onClick={() => {
                setOpen(false);
              }}
            >
              <ApplyButton text="無料ご相談はこちら" />
            </Stack>
          </Stack>
        </div>
      </FlexDrawer>
    </Stack>
  );
};

export default Header;
