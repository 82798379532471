import { useEffect } from 'react';

export const useBodyScrollLock = (shouldLock: boolean) => {
  useEffect(() => {
    if (shouldLock) {
      // スクロール位置を保存
      const scrollPosition = window.scrollY;

      // スクロールをロック
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = '100%';

      return () => {
        // クリーンアップ時にスタイルを元に戻す
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';

        // スクロール位置を復元
        window.scrollTo(0, scrollPosition);
      };
    }
  }, [shouldLock]);
};
