import { Stack } from "@mui/material";
import React, { useContext } from "react";
import YouTube from "react-youtube";
import DefaultDividerIons from "../../atomics/ions/divider/DefaultDivider.Ions";
import DefaultImgIons from "../../atomics/ions/img/DefaultImg.Ions";
import { BodyTextIons } from "../../atomics/ions/text/body/BodyText.Ions";
import { BodyTextBoldIons } from "../../atomics/ions/text/body/BodyTextBold.Ions";
import { MyContext } from "../../router/router";
import "./youtubeCard.css";

interface Props {
  videoId?: string;
  imgSrc?: string;
  title: string;
  description: string;
  fontSize: number;
  plan: "light" | "basic" | "standard";
}

// コンテストのミニカルーセルを実装
const YoutubeCard: React.FC<Props> = (props) => {
  const { isXS, isSmall, isLarge, isMedium } = useContext(MyContext);

  const width = isXS
    ? "100%"
    : isSmall
    ? 280
    : isMedium
    ? 400
    : isLarge
    ? 450
    : 0;

  // propsでwidthを渡せないとかいう極上のバグがあったので、is??で直接指定
  const opts = {
    width: width,
    height: typeof width === "string" ? undefined : width * (3 / 6),
    playerVars: {
      autoplay: 0,
    },
  };

  const _isMobile = isXS || isSmall;
  return (
    <Stack width={"100%"} alignItems={"center"} bgcolor={"#F5FBFE"}>
      <Stack
        spacing={2}
        direction={isXS ? "column" : "row"}
        alignItems={"flex-start"}
        width={"100%"}
        p={_isMobile ? 2 : 4}
        pb={4}
      >
        {props.videoId && <YouTube videoId={props.videoId} opts={opts} />}
        {props.imgSrc && (
          <DefaultImgIons
            img_src={props.imgSrc}
            width={width}
            className="youtube-card-img"
          />
        )}
        <Stack spacing={_isMobile ? 1 : 2}>
          <BodyTextBoldIons text={props.title} size="normal" />
          <BodyTextIons text={props.description} size="extra-small" />

          <BodyTextBoldIons
            text={
              props.plan === "light"
                ? "ライトプラン(初期費用 0円 + 20 %)"
                : props.plan === "basic"
                ? "ベーシックプラン(初期費用 15万円(税抜) + 20 %)"
                : "スタンダードプラン(初期費用 30万円(税抜) + 20 %)"
            }
            className="youtube-card-plan"
          />
          <Stack spacing={1}>
            <Stack direction={"row"} spacing={1}>
              {["海外配送代行", "商品選定", "ページ運営"].map((text) => (
                <div className="youtube-card-cell">
                  <BodyTextIons
                    text={text}
                    rows={1}
                    color="white"
                    size="extra-small"
                  />
                </div>
              ))}
            </Stack>
            {(props.plan === "basic" || props.plan === "standard") && (
              <Stack direction={"row"} spacing={1}>
                {["ページ制作(構成・翻訳)", "集客戦略"].map((text) => (
                  <div className="youtube-card-cell">
                    <BodyTextIons
                      text={text}
                      rows={1}
                      color="white"
                      size="extra-small"
                    />
                  </div>
                ))}
              </Stack>
            )}
            {props.plan === "standard" && (
              <Stack direction={"row"} spacing={1}>
                {["画像撮影・編集", "動画撮影・編集"].map((text) => (
                  <div className="youtube-card-cell">
                    <BodyTextIons
                      text={text}
                      rows={1}
                      color="white"
                      size="extra-small"
                    />
                  </div>
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <DefaultDividerIons width={"90%"} />
    </Stack>
  );
};

export default YoutubeCard;
