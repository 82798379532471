import { Stack } from "@mui/material";
import CrofunCarousel from "../atomics/organisms/carousel/CrofunCarousel";
import TopBannerCarousel from "../atomics/organisms/carousel/TopBannerCarousel";
import About from "../components/Main/About";
import Amount from "../components/Main/Amount";
import Contact from "../components/Main/Contact";
import ContactBanner from "../components/Main/ContactBanner";
import Flow from "../components/Main/Flow";
import Merit from "../components/Main/Merit";
import News from "../components/Main/News";
import Service from "../components/Main/Service";
import WhatsCF from "../components/Main/WhatsCF";
import Works from "../components/Main/Works";

interface Props {
  isMobile: boolean;
}

const HomePage = ({ isMobile }: Props) => {
  return (
    <Stack
      alignItems={"center"}
      bgcolor={"white"}
      position={"relative"}
      spacing={0}
      paddingTop={isMobile ? "60px" : "60px"}
    >
      {/* メインコンポーネントをここに記述 */}
      <TopBannerCarousel
        isBanner={isMobile}
        banner_img_src={["BANNER1.png", "BANNER2-1.png", "BANNER3-3.png"]}
        slide_min_width={isMobile ? 300 : 800}
      />

      <CrofunCarousel
        slide_min_width={isMobile ? 260 : 370}
        card_width={isMobile ? 250 : 350}
        isSP={isMobile}
        playOnInit
      />
      <News />
      <About />
      <WhatsCF />
      <Merit />
      <Service />
      <Flow />
      <ContactBanner />
      <Amount />
      <Works />
      <Contact />
    </Stack>
  );
};

export default HomePage;
