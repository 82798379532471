import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import TitleColumn from '../../Title/Column';
import {BaseGrid} from "reidea-common"

const Amount: React.FC = () => {

    const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall } = useContext(MyContext);

    const minCellWidth = 150;
    const minLeftColumnWidth = 120; // 左端の列の最小幅を定義
    const cellHeight = 70;
    const [cellWidth, setCellWidth] = useState<number>(Math.max(window.innerWidth * contentRatio / 5, minCellWidth));
    const [leftColumnWidth, setLeftColumnWidth] = useState<number>(Math.max(window.innerWidth * contentRatio / 6, minLeftColumnWidth)); // 左端の列の幅を別途計算
    const offsetHeight = 20;

    const twoRowsCellComponent = (text1:string, text2:string, fontSizeRatio?: number, offsetHeight?:number, strikethrough?: boolean) => {
        return(
            <Stack width={cellWidth} height={cellHeight+(offsetHeight ?? 0)} justifyContent={"center"} alignItems={"center"}>
                <Typography color={"#FE595B"} fontWeight={"bold"} fontSize={mainFontSize+12} sx={strikethrough ? { textDecoration: 'line-through' } : {}}>
                    {text1}
                </Typography>
                <Typography color={"#383E86"} fontWeight={"bold"} fontSize={mainFontSize-2}>{text2}</Typography>
            </Stack>
        )
    } 

    const normalCellComponent = (text:string, fontSizeRatio?: number, offsetHeight?:number, color?: string, emphasized?: boolean) => {
        const defaultColor = (text === "⚫︎" || text === "×") ? "#383E86" : "#FE595B";
        return(
            <Typography 
                width={cellWidth} 
                fontWeight={"bold"} 
                height={cellHeight} 
                textAlign={"center"} 
                alignItems={"center"} 
                color={color || defaultColor} 
                fontSize={(mainFontSize+12)* (fontSizeRatio ?? 1)} 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(emphasized && { 
                        backgroundColor: '#E6F7FF',
                    }),
                }}
            >
                {text}
            </Typography>
        )
    } 

    // useEffect(() => {
    //     const handleResize = () => {
    //         setCellWidth(Math.max(window.innerWidth * contentRatio / 5, minCellWidth));
    //         setLeftColumnWidth(Math.max(window.innerWidth * contentRatio / 6, minLeftColumnWidth)); // 左端の列の幅も更新
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [isSmall, isXS, contentRatio]); 

    useEffect(() => {
        const handleResize = () => {
            // isXSがtrueなら小さめの値、falseなら通常の値を設定
            setCellWidth(isXS 
                ? Math.max(window.innerWidth * contentRatio / 10, minCellWidth)  // XSサイズのとき
                : Math.max(window.innerWidth * contentRatio / 5, minCellWidth)  // それ以外のとき
            );
    
            // 左端の列の幅も同様に処理
            setLeftColumnWidth(isXS 
                ? Math.max(window.innerWidth * contentRatio / 20, minCellWidth)  // XSサイズのとき
                : Math.max(window.innerWidth * contentRatio / 6, minCellWidth)  // それ以外のとき
            );
        };
    
        window.addEventListener('resize', handleResize);
    
        // 初期設定でも呼び出し
        handleResize();
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isSmall, isXS, contentRatio]);
    

    const _isMobile = isXS || isSmall;

    const leftColumnItems = [
        "初期費用",
        "手数料",
        "運営・支援者\nとのやり取り",
        "海外向け広告",
        "海外配送代行",
        "プロジェクト\nページ制作",
        "画像動画\n撮影・編集"
    ];

    return (
        <Stack id={"amount"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"} py={_isMobile ? 8 : 15}>
            <TitleColumn title='料金表' title_en='AMOUNT' fontSize={subTitleFontSize+5}/>
            <Stack mt={4} width={100*contentRatio+"%"} justifyContent={"center"} sx={{overflowX:"auto"}}>
                <Stack direction={"row"} width={leftColumnWidth + cellWidth*4} position={"relative"}>
                    <Box position={"sticky"} left={0} zIndex={4} width={leftColumnWidth} height={cellHeight} bgcolor={"white"}/>
                    <Stack direction={"row"} width={cellWidth*4} alignItems={"center"} height={cellHeight} sx={{background:'linear-gradient(to right, #3cb9a0, #164e8d)'}}>
                        {["ライト", "ベーシック", "スタンダード", "他社事例"].map((value: string, index:number) => (
                            <React.Fragment key={index}>
                                <Typography 
                                    width={cellWidth} 
                                    textAlign={"center"}
                                    color={"white"} 
                                    fontWeight={600} 
                                    fontSize={index === 1 ? mainFontSize+4 : mainFontSize+2} 
                                    letterSpacing={1.2}
                                    sx={{
                                        ...(index === 1 && { 
                                            textShadow: '0px 0px 10px rgba(255,255,255,0.5)',
                                            fontWeight: 600,
                                            backgroundColor: '#3cb9a0',
                                        })
                                    }}
                                >
                                    {value}
                                </Typography>
                                {index !== 3 && <Divider orientation='vertical' sx={{backgroundColor:"white", width:"0.7px", height:"60%"}} />}
                            </React.Fragment>
                        ))}
                    </Stack>
                </Stack>

                <Stack direction={"row"} position={"relative"} width={leftColumnWidth + cellWidth*4}>
                    <Stack left={0} zIndex={1} direction={"column"} position={"sticky"} width={leftColumnWidth} justifyContent={"center"} alignItems={"center"} height={cellHeight*7+offsetHeight} sx={{background:'linear-gradient(to bottom, #3cb9a0, #164e8d)'}}>
                        {leftColumnItems.map((value: string, index:number) => (
                            <React.Fragment key={index}>
                                <Stack justifyContent={"center"} width={leftColumnWidth} height={cellHeight+(index === 0 ? 20 : 0)} textAlign={"center"} color={"white"} fontWeight={600} fontSize={mainFontSize+2} letterSpacing={1.2} sx={{
                                    whiteSpace:"pre-wrap"
                                }} >
                                    {value}
                                    {index === 4 && <Typography fontSize={mainFontSize-4}>※別途オプション</Typography>}
                                </Stack>
                                {index !== leftColumnItems.length - 1 && <Divider sx={{backgroundColor:"white", width:"70%", }} />}
                            </React.Fragment>
                        ))}
                    </Stack>

                    <Stack bgcolor={"#F5FBFE"} direction={"column"} width={cellWidth*4} alignItems={"center"} height={cellHeight*7+offsetHeight}>
                        <BaseGrid cols={4} spacingY={0} items={
                            [
                                normalCellComponent("¥ 0", 1, offsetHeight),
                                <Stack width={cellWidth} height={cellHeight+offsetHeight} justifyContent={"center"} alignItems={"center"} sx={{
                                    backgroundColor: '#E6F7FF',
                                }}>
                                    <Typography color={"#383E86"} fontWeight={"bold"} fontSize={mainFontSize+5} sx={{ textDecoration: 'line-through', marginBottom: '-5px' }}>
                                        ¥ 198,000
                                    </Typography>
                                    <Typography color={"#FE595B"} fontWeight={"bold"} fontSize={mainFontSize+12}>
                                        ¥ 148,000
                                    </Typography>
                                    <Typography color={"#383E86"} fontWeight={"bold"} fontSize={mainFontSize-2}>
                                        (税込162,800円)
                                    </Typography>
                                </Stack>,
                                <Stack width={cellWidth} height={cellHeight+offsetHeight} justifyContent={"center"} alignItems={"center"}>
                                    <Typography color={"#383E86"} fontWeight={"bold"} fontSize={mainFontSize+5} sx={{ textDecoration: 'line-through', marginBottom: '-5px' }}>
                                        ¥ 398,000
                                    </Typography>
                                    <Typography color={"#FE595B"} fontWeight={"bold"} fontSize={mainFontSize+12}>
                                        ¥ 298,000
                                    </Typography>
                                    <Typography color={"#383E86"} fontWeight={"bold"} fontSize={mainFontSize-2}>
                                        (税込327,800円)
                                    </Typography>
                                </Stack>,
                                normalCellComponent("¥ 500,000~", 0.75, offsetHeight, "#888888"),
                                normalCellComponent("20%"), normalCellComponent("20%", 1, 0, undefined, true), normalCellComponent("20%"), normalCellComponent("25%", 0.75, 0, "#888888"), 
                                normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6, 0, undefined, true), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.2, 0, "#888888"),
                                normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.6, 0, undefined, true), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.2, 0, "#888888"),
                                normalCellComponent("×", 1.6), normalCellComponent("⚫︎", 1.6, 0, undefined, true), normalCellComponent("⚫︎", 1.6), normalCellComponent("×", 1.2, 0, "#888888"),
                                normalCellComponent("×", 1.6), normalCellComponent("⚫︎", 1.6, 0, undefined, true), normalCellComponent("⚫︎", 1.6), normalCellComponent("⚫︎", 1.2, 0, "#888888"),
                                normalCellComponent("×", 1.6), normalCellComponent("×", 1.6, 0, undefined, true), normalCellComponent("⚫︎", 1.6), normalCellComponent("×", 1.2, 0, "#888888"),
                            ]
                        }></BaseGrid>
                    </Stack>
                    
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Amount;