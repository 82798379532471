import React from 'react';
import DefaultCarouselIons from '../../ions/carousel/DefaultCarousel.Ions';
import DefaultImgIons from '../../ions/img/DefaultImg.Ions';

interface ButtonProps {
  isBanner?: boolean;
  banner_img_src: string[];
  slide_min_width?: number;
}

const TopBannerCarousel: React.FC<ButtonProps> = (props) => {
  return (
    <DefaultCarouselIons
      isBanner={props.isBanner || false}
      slide_min_width={props.slide_min_width}
      items={props.banner_img_src.map((src: string) => (
        <DefaultImgIons img_src={src} alt="top-banner-reidea-src" className={`top-banner-img ${props.isBanner ? "banner" : ""}`} />
      ))}
      playOnInit
    />
  );
};

export default TopBannerCarousel;
