import React from 'react';
import ImgAtoms from '../../atoms/img/Img.Atoms';
import { ImgAtomsProps } from '../../atoms/img/Img.type';
import './Img.Ions.css';

interface IdeaImgIonsProps extends ImgAtomsProps {
  onDelete?: () => void;
  nowOpen?: boolean;
  commingSoon?: boolean;
}

const IdeaImgIons: React.FC<IdeaImgIonsProps> = React.memo((props) => {
  const { onDelete, nowOpen, commingSoon, ...imgProps } = props;

  return (
    <div className="idea-img-ions-wrapper">
      <ImgAtoms {...imgProps} className={`idea-img-ions ${props.className}`} />
      {onDelete && (
        <button
          className="idea-img-ions-delete-button"
          onClick={onDelete}
          aria-label="Delete image"
        >
          ×
        </button>
      )}
      {nowOpen && (
        <div className="idea-img-ions-added-container">
          <ImgAtoms
            img_src={'now-open.png'}
            alt={props.alt + '-now-open'}
            className="idea-img-ions-added-img"
          />
        </div>
      )}
      {commingSoon && (
        <div className="idea-img-ions-added-container">
          <ImgAtoms
            img_src={'comming-soon.png'}
            alt={props.alt + '-comming-soon'}
            className="idea-img-ions-added-img"
          />
        </div>
      )}
    </div>
  );
});

export default IdeaImgIons;
