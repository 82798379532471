import React from "react";
import { CROUD_FUNDINGS_DATA } from "../../../const/croundFundings";
import { CrofunCardType } from "../../../types/Crofun.type";
import DefaultCarouselIons from "../../ions/carousel/DefaultCarousel.Ions";
import { CrofunCardMolecules } from "../../molecules/card/crofun/CrofunCard.Molecules";
import CrowdFundingPopup from "../popup/CrowdFundingPopup";
import "./CrofunCarousel.css";

interface IdeaCarouselProps {
  slide_min_width: number;
  card_width: number;
  isSP: boolean;

  isTwoRows?: boolean;
  playOnInit?: boolean;
}

const CrofunCarousel: React.FC<IdeaCarouselProps> = (props) => {
  const [selectedCrowdFunding, setSelectedCrowdFunding] =
    React.useState<CrofunCardType>(CROUD_FUNDINGS_DATA.chawaka);

  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <section className="crofun-calousel-bg-wrapper">
      <DefaultCarouselIons
        slide_min_width={props.slide_min_width}
        isTwoRows={false}
        items={Object.entries(CROUD_FUNDINGS_DATA).map(([key, crofun]) => (
          <CrofunCardMolecules
            key={key}
            crofun={crofun}
            width={props.card_width}
            text_size={props.isSP ? "extra-small" : "small"}
            hover_effect
            display_description
            onClick={() => {
              setSelectedCrowdFunding(crofun);
              setOpen(true);
            }}
          />
        ))}
        displayButton={false}
        playOnInit={props.playOnInit ?? true}
        autoScroll
      />
      <div className="crofun-calousel-bg" />

      <CrowdFundingPopup
        open={open}
        onClose={() => setOpen(false)}
        crowdfund={selectedCrowdFunding}
        isSP={props.isSP}
      />
    </section>
  );
};

export default CrofunCarousel;
