import React from 'react';
import { TextAtomsProps, TextSizeType } from '../../../atoms/text/Text.type';
import { TextAtoms } from '../../../atoms/text/Text.Atoms';

interface TextProps extends TextAtomsProps {
  size?: TextSizeType;
}

export const BodyTextBoldIons: React.FC<TextProps> = React.memo((props) => (
  <TextAtoms {...props} size={props.size} color={props.color} bold={'medium'} />
));
