import React from 'react';
import './Divider.Atoms.css';
import { DividerAtomsProps } from './Divider.type';

const DividerAtoms: React.FC<DividerAtomsProps> = React.memo(
  ({
    className = '',
    color = 'default',
    thickness = 'normal',
    spacing = 'normal',
    width = '100%',
    margin,
  }) => {
    return (
      <div
        className={`divider-atoms ${color} ${thickness} ${spacing} ${className}`}
        style={{
          width,
          margin,
        }}
      />
    );
  },
);

export default DividerAtoms;
